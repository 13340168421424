import { render, staticRenderFns } from "./chaiOilCard.vue?vue&type=template&id=8706f166&scoped=true&"
import script from "./chaiOilCard.vue?vue&type=script&lang=js&"
export * from "./chaiOilCard.vue?vue&type=script&lang=js&"
import style0 from "./chaiOilCard.vue?vue&type=style&index=0&id=8706f166&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8706f166",
  null
  
)

export default component.exports