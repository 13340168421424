<template>
  <div v-if="isShow"  class="addOil-container">
    <van-popup class="popup" v-model="popup"><img class="popup" src="@/assets/b.gif" alt=""/></van-popup>
    <!--    通知栏-->
    <div class="vip-tips-content">
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
        <span>请勿在加油机旁使用手机</span>
        <div style="display: flex">
          <van-icon :name="iconVip" size="24"/>
          <span style="padding-left: 2px"> {{ homeData.phone }}</span>
        </div>
      </van-notice-bar>
    </div>
    <!--    地址显示-->
    <van-card v-show="addrCardShow" style="background: #fff;width: 95%;margin: 15px auto 0 auto;border-radius: 6px;
    box-shadow: 0 0 10px -6px #000;" num="" :desc="homeData.address" :title="homeData.title" :thumb="addOilSrc"/>
    <!--  汽油柴油天然气选项卡-->
    <van-tabs color="#fff" type="card" sticky
              v-model="activeName"
              style="margin: 15px auto 0 auto;width: 95%;box-shadow: 0 0 10px -6px #000;">
      <van-tab name="qiyou" title="汽油" v-if="qiOilIsShow">
        <p class="chooseOilNum">请选择油号</p>
        <div class="oil-number-box">
          <oilCard
              :qiOil="qiOil"
              :clearSelect="clearSelect"
              @getProductId="getProductId">
          </oilCard>
        </div>
      </van-tab>
      <van-tab name="cng" title="CNG（天然气）" v-if="CNGIsShow">
        <p class="chooseOilNum">请选择油号</p>
        <div class="oil-number-box">
          <CNGCard :CNG="CNG"
                   :clearSelect="clearSelect"
                   @getProductId="getProductId"></CNGCard>
        </div>
      </van-tab>
      <van-tab name="chaiyou" title="柴油" v-if="chaiOilIsShow">
        <p class="chooseOilNum">请选择油号</p>
        <div class="oil-number-box">
          <chai-oil-card :chaiOil="chaiOil" @getProductId="getProductId"></chai-oil-card>
        </div>
      </van-tab>
    </van-tabs>
    <!--    输入金额-->
    <div class="bottom-content">
      <p class="chooseOilPrice">请输入金额</p>
      <van-cell-group style=" box-shadow: 0 0 10px -6px #000;" :style="`border:1px solid ${activeBorderColor};backgroundColor:${activeColor}`">
        <van-field
            ref="priceInput"
            v-model="oilPrice"
            label="￥"
            placeholder="请输入油机金额"
            @click="popKeyBord"
            readonly
            type="number"
            :style="`backgroundColor:${activeColor}`"
        />
        <div class="rightIcon" :style="`borderLeft:1px solid ${activeBorderColor}`">
          {{ countRise }}{{ unit }}
        </div>
      </van-cell-group>

      <div class="price-card-box">
        <div class="price-card" @click="chooseOneHunderd">￥100</div>
        <div class="price-card" @click="chooseTwoHunderd">￥200</div>
        <div class="price-card" @click="chooseThreeHunderd">￥300</div>
        <div class="price-card" @click="chooseFiveHunderd">￥500</div>
      </div>

    </div>
    <div class="bottom-btn-box">
      <van-button type="primary" block @click="submitData" >下一步</van-button>
    </div>
    <van-number-keyboard
        type="number"
        :show="keyBordShow"
        theme="custom"
        extra-key="."
        close-button-text="完成"
        :hide-on-click-outside="outside"
        @blur="keyBordHidden "
        @input="onInput"
        @delete="onDelete"/>
  </div>

</template>

<script>
import {Toast} from 'vant';
import OilCard from "@/components/oilCard/oilCard";
import CNGCard from "@/components/CNGCard/CNGCard";
import chaiOilCard from "@/components/chaiOilCard/chaiOilCard";
import util from "@/util/util";
import {ALI_APP_ID, WX_APP_ID} from "@/util/constant";

export default {
  name: "addOil",
  components: {
    OilCard,
    CNGCard,
    chaiOilCard,
  },
  data() {
    return {
      countNum:1,
      activeBorderColor:"",//选中的border颜色修改
      activeColor:"",//选中输入框后修改颜色
      isShow:false,//首页加载不显示
      clearSelect: "",//传给card组件清除productID
      queryCode: "",//下一步传入的code
      activeName: "",//默认选择的选项卡
      unit: "L",//输入框后的单位
      popup: true,//显示git动画
      iconSrc: require("@/assets/img/8.png"),
      iconVip: require("@/assets/img/1.png"),
      addOilSrc: require("@/assets/img/3.png"),
      oilPrice: 0,//支付金额
      keyBordShow: false,
      addrCardShow: true,//调起支付键盘后隐藏地址栏
      switchValue: 0,
      homeData: {},
      qiOil: [],//传递给card组件使用
      CNG: [],
      chaiOil: [],
      chaiOilIsShow: false,
      CNGIsShow: false,
      qiOilIsShow: false,//是否显示选项卡的选项
      payProductId: null,//产品请求Id
      paySource: null,
      countRise: 0,//根据输入金额计算对应的升数
      guaiPaiPrice: 0,//挂牌价，计算升数
      form: {
        openId: undefined,
        id: undefined,
        paySource: 0,//支付类型
      },
      orderId: "",
      outside:false,
    }
  },
  mounted() {
    this.init();//初始化方法
  },
  watch: {
    oilPrice: function () {
      this.countRise = (Number(this.oilPrice) / Number(this.guaiPaiPrice)).toFixed(2) + '' === NaN + '' ? 0.00 : (Number(this.oilPrice) / Number(this.guaiPaiPrice)).toFixed(2);
      // window.console.log( this.oilPrice,"watch2");
      // let ab = this.oilPrice.toString().includes('.')
      if(this.oilPrice.toString().includes(".")) {
        window.console.log("当前是小数",this.oilPrice);
        let pointIndex = this.oilPrice.toString().indexOf(".");//获取小数点所在位置
        let numA = this.oilPrice.toString().substr(0,pointIndex);//截取小数点前数字
        let numC = this.oilPrice.toString().substr(pointIndex+1,this.oilPrice.toString().length);//截取小数点后数字
        let numB = '';
        window.console.log("小数点后有",numC.length);
        if(numC.length >= 2){//如果小数点后数字超过2位
          numB = this.oilPrice.toString().substr(pointIndex+1,2);
        }else {
          numB = this.oilPrice.toString().substr(pointIndex+1,1);
        }
        window.console.log(numB,"numB")
        window.console.log(`${numA}.${numB}`,"这是一个最后的数");
        this.oilPrice = `${numA}.${numB}`;
      }
    },
    chaiOil: function () {
      this.chaiOilIsShow = this.chaiOil.length !== 0 ? true : false;
    },
    CNG: function () {
      this.CNGIsShow = this.CNG.length !== 0 ? true : false;
    },
    qiOil: function () {
      this.qiOilIsShow = this.qiOil.length !== 0 ? true : false;
    },
    //切换选项卡执行
    activeName: function () {
      //清除页面数据
      let countRise = 0;
      this.countRise = countRise.toFixed(2);
      this.clearSelect = (new Date().valueOf());
      if(this.countNum === 1 && this.activeName==="qiyou"){
        this.payProductId = this.qiOil[0].id;
        this.guaiPaiPrice = this.qiOil[0].listingPrice;
        this.countNum++;
      }else {
         this.handleBlur();
        this.payProductId = null;
      }


      this.oilPrice = 0;
      if (this.activeName === 'cng') {
        this.unit = "m³";
      } else {
        this.unit = "L";

      }
      this.$forceUpdate();
    }
  },
  methods: {
    // 初始化数据
    init() {
      // 当前路由参数
      let query = this.$route.query;
      let id = util.getData('id');
      let  codes = util.getData('codes');
      if (!id){
        id = query.id
        util.setData('id',id);
      }
      if (!codes){
        codes = query.codes;
        util.setData('codes',codes)
      }
      // this.queryCode = query.codes;
      var _that = this;
      if (id && codes) {
        this.form.id = id;
        this.queryCode = codes;
      } else {
        Toast('请重新扫描二维码')
        return;
      }
      // 判断浏览器
      let isAlipayOrWechat = util.isAlipayOrWechat();
      if (isAlipayOrWechat == "other") {
        Toast("请用微信或支付宝浏览器打开!")
        return;
      }
      // 获取当前url
      let url = window.location.href;
      // 微信
      if (isAlipayOrWechat == "wechat") {
        this.form.paySource = '0';
        // 判断当前url是否包含code参数
        if (query.code) {
          // 获取openid
          this.getOpenId(query.code, openId => {
            _that.query(_that.form.id, openId)
          });
        } else {
          // 如果不包含code参数，则跳转到微信授权页面
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WX_APP_ID}&redirect_uri=${encodeURIComponent(url)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        }
      }
      // 支付宝
      if (isAlipayOrWechat == "alipay") {
        this.form.paySource = '1';
        // 判断当前url是否包含auth_code参数
        if (query.auth_code) {
          // 获取openid
          this.getUserId(query.auth_code, openId => {
            _that.query(_that.form.id, openId)
          });
        } else {
          // 如果不包含code参数，则跳转到微信授权页面
          window.location.href = `https://openauth.alipaydev.com/oauth2/publicAppAuthorize.htm?app_id=${ALI_APP_ID}&scope=auth_base&redirect_uri=${encodeURIComponent(url)}`
        }
      }
    },
    // 支付宝获取userid
    async getUserId(code, callback) {
      let openId = util.getData('openId')
      if (!openId) {
        const obj = await this.$get('/jsapi/get_userid', {
          code: code
        });
        if (obj.code != 200) {
          return
        }
        openId = obj.data;
      }
      util.setData('openId', openId);
      this.form.openId = openId;
      callback(openId)
    },
    // 获取openid
    async getOpenId(code, callback) {
      let openId = util.getData('openId')
      if (!openId) {
        const obj = await this.$get('/jsapi/get_openid', {code: code});
        if (obj.code != 200) {
          return
        }
        openId = obj.data;
      }
      util.setData('openId', openId);
      this.form.openId = openId;
      callback(openId)
    },
    // //获取首页数据接口
    query(id = undefined, openId = undefined) {
      this.$get("/jsapi/get_info", {
        source: this.form.paySource,
        id: id,
        openId: openId,
      }).then(res => {
        const data = res.data;
        // 判断首页数据是否为vip客户
        if (res.code == 200) {
          this.popup = false;
          if (data.isVip !== 1) {
            window.location.href = `https://wlzgb.dongkebao.com/?id=${id}&codes=${this.queryCode}`
          } else {
            this.isShow = true;
            let countRise = 0;
            this.homeData = res.data;
            this.guaiPaiPrice = this.homeData
            this.qiOil = this.homeData.types.type0;
            this.countRise = countRise.toFixed(2);
            this.CNG = this.homeData.types.type1;
            this.chaiOil = this.homeData.types.type2;
            if (this.homeData.sort === 0) {
              this.activeName = "qiyou";
              this.payProductId = this.homeData.types.type0[0].id;//默认选择的id
            } else if (this.homeData.sort === 1) {
              this.activeName = "cng"
            } else {
              this.activeName = "chaiyou"
            }

          }
        }
      }).catch(err => {
        Toast(err)
      })
    },
    // 请求
    // payment() {
    //   let that = this;
    //   let code = util.getData('id');
    //   if (!code) {
    //     Toast("请重新扫描二维码");
    //     return
    //   }
    //   if (this.numberval <= 0) {
    //     Toast("请输入支付金额")
    //     return
    //   }
    //   this.$json("/jsapi/payment_h5", {
    //     zhandianId: code,
    //     money: this.numberval,
    //     paySource: this.paySource,
    //     openId: this.form.openId
    //   }).then(res => {
    //     if (res.code == 200) {
    //       that.display = 'none';
    //       that.img = 'none';
    //       that.$refs.pay.getMsg(res.data.message);
    //     }
    //   })
    // },
    // 输入框事件
    onInput(value) {
      if (this.payProductId === null) {
        Toast("请选择油号后再输入金额");
        return;
      }

      this.countRise = (Number(this.oilPrice) / Number(this.guaiPaiPrice)).toFixed(2);
      if (this.switchValue === 0) {
        this.oilPrice = value;
        this.switchValue++;
      } else {
        const num = value.toString();
        this.oilPrice += num;
      }
    },
    onDelete() {
      let len = this.oilPrice.length;
      this.oilPrice = this.oilPrice.substring(0, len - 1);

    },
    // 聚焦输入框弹出键盘并修改输入框样式
    popKeyBord() {
      if(this.payProductId){
          this.activeBorderColor="orange";
          this.activeColor="#f9f2db";
      }
      document.activeElement.blur();
      this.addrCardShow = false
      this.keyBordShow = true;
    },
    // 修改输入框样式
    handleBlur(){
          this.activeBorderColor="";
          this.activeColor="";
    },
    // 键盘隐藏
    keyBordHidden() {
      if( this.payProductId&&this.oilPrice!=0 ){
            this.activeBorderColor="orange";
            this.activeColor="#f9f2db";
      }else{
          this.handleBlur();
     
      }
      this.keyBordShow = false;
      this.addrCardShow = true;
    },
    //  保存产品id
    getProductId(e) {
      this.payProductId = e.id ;
      this.guaiPaiPrice = e.guaiPaiPrice;
    },
    //提交数据
    async submitData() {
        this.$copyText(this.homeData.tuiguang).then(function (e) {
        window.console.log(e)
      }, function (e) {
        window.console.log(e)
      });
      const data = {
        productId: this.payProductId,//产品ID
        paySource: this.form.paySource,
        money: Number(this.oilPrice),
        openId: this.form.openId,
        code: this.queryCode,
      }
      window.console.log(this.oilPrice,"oilPrice");
      let oilPrice = Number(this.oilPrice).toFixed(2);
      window.console.log(oilPrice,"保留两位小数后的值");
      window.console.log(oilPrice>0,"是否大于0")

      if (this.oilPrice < 0 || Number(this.oilPrice) + '' === '' + NaN ) {
        Toast("请输入正确的数值");
        return;
      }

      if (data.productId === null || data.openId === '' || this.oilPrice === 0) {
        Toast("请选择对应的油号和金额后提交")
        return;
      }
      const res = await this.$json("/jsapi/create_vip_order", data);//请求创建订单接口
      if (res.code === 200) {
        this.orderId = res.data;//新建订单的订单号
        util.setData("action",1)
        this.$router.push({
          name: 'payConfirm',
          path: "payConfirm",
          query: {
            orderId: this.orderId//传到确认付款页面的query
          }
        })
      }
    },
    //  选择了一百选项卡
    chooseOneHunderd() {
      // this.$refs.priceInput.blur();
      // window.console.log(this.$refs.priceInput.blur);
      this.keyBordShow = false;
      this.addrCardShow = true;
      if (this.payProductId === null) {
        Toast("请选择油号后再输入金额");
        return;
      }
      this.oilPrice = 100;
      this.countRise = (100 / Number(this.guaiPaiPrice)).toFixed(2);
    },
    //  选择了2百选项卡
    chooseTwoHunderd() {
      this.keyBordShow = false;
      this.addrCardShow = true;

      if (this.payProductId === null) {
        Toast("请选择油号后再输入金额");
        return;
      }
      this.oilPrice = 200;
      this.countRise = (200 / Number(this.guaiPaiPrice)).toFixed(2);
    },
    //  选择了3百选项卡
    chooseThreeHunderd() {
      this.keyBordShow = false;
      this.addrCardShow = true;

      if (this.payProductId === null) {
        Toast("请选择油号后再输入金额");
        return;
      }
      this.oilPrice = 300;
      this.countRise = (300 / Number(this.guaiPaiPrice)).toFixed(2);
    },
    //  选择了5百选项卡
    chooseFiveHunderd() {
      this.keyBordShow = false;
      this.addrCardShow = true;

      if (this.payProductId === null) {
        Toast("请选择油号后再输入金额");
        return;
      }
      this.oilPrice = 500;
      this.countRise = (500 / Number(this.guaiPaiPrice)).toFixed(2);
    },
  },
}
</script>

<style scoped lang="less">
.addOil-container {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
}


/deep/ .van-notice-bar__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

//居中加油站在盒子
/deep/ .van-card__content {
  justify-content: center;
}

//高原加油站文字
.van-card__title {
  max-height: 32px;
  font-weight: 700;
  line-height: 18px;
  font-size: 18px;
}

//隐藏默认组件自带的￥和x盒子
/deep/ .van-card__bottom {
  display: none;
}

//价格选择框的背景色
.price-card-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 10px;
  background: #fff;
}

//底部输入框
.bottom-content {
  background: #fff;
  width: 95%;
  margin: 15px auto 0 auto;
  padding: 1px 10px;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 0 10px -6px #000;

  .price-card-box {
    .price-card {
      background: #eee;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 5px;
    }
  }
}

//柴油汽油的文字颜色
.van-tabs__nav--card.van-tab.van-tab--active {
  color: #000;
  font-weight: 700;
}

.oil-number-box {
  height: 140px !important;
}

.oil-number-box .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
  width: 50% !important;
}

.chooseOilNum {
  margin: 0;
  font-size: 13px;
  padding-left: 12px;
  font-weight: 700;
}

.chooseOilPrice {
  font-size: 13px;
  padding: 0;
  font-weight: 700;
}

.rightIcon {
  height: 50px;
  border-left: 1px solid gray;
  line-height: 50px;
  padding: 0 15px;
}

.bottom-btn-box {
  width: 95%;
  margin: 0 auto;
  padding-top: 30px;
  box-sizing: border-box;

  /deep/ .van-button--block {
    border-radius: 20px;
    background: #2380ff;
  }
}

.popup {
  width: 100%;
  height: 100vh;
  z-index: 999;
}

/deep/ .van-tab__text--ellipsis {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #000;
}

//汽油柴油选项卡导航栏
/deep/ .van-tabs__nav--card {
  box-sizing: border-box;
  height: 40px;
  border: none !important;
  background-color: #ececed !important;
  margin: 0 !important;

}

/deep/ .van-tabs--card {
  overflow: hidden;
  border-radius: 6px;
}

//选项卡的背景色
/deep/ .van-tabs__content {
  background: #fff;
  padding-top: 5px;
}


//顶部提示框背景色
/deep/ .van-notice-bar {
  background: #2380ff !important;
}

//提醒框文字颜色
/deep/ .van-notice-bar {
  color: #fff !important;
}

//输入框的左右padding
/deep/ .van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 5px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
  border-radius: 8px;
}
// /deep/ .vancell:focus{
//   background-color: #f9f2db;

// }
// /deep/ .vancell{
//   background-color: #f9f2db;

// }
//输入框左侧文字的长度
/deep/ .van-field__label {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  flex: none;
  box-sizing: border-box;
  width: 1.5em;
  color: #646566;
  text-align: left;
  word-wrap: break-word;
  margin: 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

/deep/ .van-field__label span {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}

//输入框样式
/deep/ .van-cell-group {
  background-color: #fff;
  border: 1px solid gray;
  margin: 15px 0;
  border-radius: 8px;
  padding: 0 2px;
  display: flex;
}
// /deep/ .van-cell-group:focus{
//   border: 1px solid orange;

// }
// /deep/ .van-cell-group:focus{
//   border: 1px solid orange;

// }
/deep/ .van-cell__value {
  position: relative;
  overflow: hidden;
  color: #969799;
  text-align: right;
  vertical-align: middle;
  word-wrap: break-word;
  height: 40px;
  line-height: 40px;
}


//地址栏的图片
/deep/ .van-card__thumb {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  flex: none;
  width: 50px;
  height: 50px;
  margin-right: 8px;
}

//高原 加油站下面的文字
/deep/ .van-card__title {
  margin-bottom: 7px;
}

/deep/ .van-button--primary {
  border: #2380ff;
}

/deep/ .van-card__content {
  min-height: 58px !important;
}

//通知栏的高度
/deep/ .van-notice-bar {
  height: 30px !important;
}


//输入框文字大小
/deep/ .van-field__control {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 0;
  color: #323233;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  border: 0;
  resize: none;
  font-size: 22px;
}

//选项卡背景色
/deep/ .nav-tabs-scroll {
  background: #fff;
}

</style>