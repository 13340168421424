<template>
  <div class="card-container">
    <ul class="nav-tabs-scroll"
        :class="tabs.length !== 1?'nav-tabs-scroll-dflex':''">
      <li v-for="(item,index) in chaiOil"
          @click="checkCard(index,item)"
          :class="activeClass === index?'active':''"
          :key="index" class="nav-item-scroll">
        <div class="youhui">优惠:{{item.discountPrice}}/L</div>
        <div class="price-box">
          <p class="oil-number">{{item.youhao}}</p>
          <p class="guaipai">挂牌价:{{item.listingPrice}}/L</p>
          <p class="vip-price">会员价:{{item.memberPrice}}/L</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "card",
  props:["chaiOil","clearSelect"], watch:{
    qiOil:function (){
      this.tabs = this.qiOil;
      this.$forceUpdate()
    },
    clearSelect:function (){
      this.activeClass = -1;
    }
  },
  data(){
    return{
      tabs: [],
      text: '',
      activeClass:-1,
    }
  },
  methods:{
    checkCard(index,item){
      this.activeClass = index;
      let data = {
        id:item.id,
        guaiPaiPrice:item.listingPrice,
      }
      this.$emit("getProductId",data)
    }
  }
}
</script>

<style lang="less" scoped>
p{
  margin: 0;
}
.nav-tabs-scroll {
  list-style: none;
  margin: 0;
  padding: 16px 0;
  overflow-x: auto;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  height: 108px;
  overflow-y: hidden;
  background: #F9FAFD;
}
.nav-tabs-scroll-dflex{
  display: flex;
  justify-content: space-between;
}
.nav-item-scroll {
  background: #fff;
  color: #24252E;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: inline-block;
  border-radius: 8px;
  height: 100%;
  width: 43%;
  box-sizing: border-box;
  margin: 0 10px;
  box-shadow: 0 0 10px -6px #000;
}
.active{
  background: #f9f2db;
  border: 1px solid orange;
}
.youhui{
  display: inline;
  background: #e15345;
  padding: 3px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #fff;
}
.price-box{
  text-align: center;
  padding-top: 5px;
  .oil-number{
    height: 40px;
    line-height: 40px;
    font-size: 34px;
    font-weight: 700;
    color: #d8b45b;
  }
  .guaipai{
    transform: scale(0.8);
  }
  .vip-price{
    transform: scale(0.9);
  }
  .guaipai,.vip-price{
    color: gray;
    font-size: 13px;
  }
}
.nav-tabs-scroll::-webkit-scrollbar-button {
  width: 10px;
  border-radius: 50%;
  background: black;
}
.nav-tabs-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #333;
  border-radius: 5px;
  background: blue;
}
//选项卡中请选择油号
.chooseOilNum{
  font-size: 12px;
  padding-left: 10px;
}

</style>
